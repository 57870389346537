import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import {
  brands,
  categories,
  clearFilters,
  clearFiltersEmptyResults,
  clearFiltersMobile,
  configuration,
  hitsPerPage,
  pagination,
  products,
  resultsNumberMobile,
  saveFiltersMobile,
  searchBox
} from './widgets';

const searchClient = algoliasearch(
  'PG4CQP2F9W',
  'ef40932896aa29197bbb1bbf9627e36f'
);

const search = instantsearch({
  searchClient,
  indexName: 'esim_devices_desc'
 ,
});

search.addWidgets([
  brands,
  categories,
  clearFilters,
  clearFiltersEmptyResults,
  clearFiltersMobile,
  configuration,
  hitsPerPage,
  pagination,
  products,
  resultsNumberMobile,
  saveFiltersMobile,
  searchBox,
]);

export default search;
