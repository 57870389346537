import { panel, refinementList } from 'instantsearch.js/es/widgets';
import { collapseButtonText } from '../templates/panel';

const categoryRefinementList = panel({
  templates: {
    header: 'Category',
    collapseButtonText,
  },
  collapsed: () => false,
})(refinementList);

export const categories = categoryRefinementList({
  container: '[data-widget="categories"]',
  attribute: 'Category',
  showMore: false,
  templates: {
    labelText: 'Display only these categories',
  },
});
